import { Api } from '@/api/types';
import { Market as MarketResponse, MarketState } from '@/types/market';

export class Market {
  public constructor(protected readonly client: Api) {}

  public list(): Promise<MarketResponse[]> {
    return this.client.get(`/marketplace/markets`);
  }

  public getMarketSummary(market: MarketResponse): Promise<MarketState> {
    return this.client.get(`/marketplace/markets/${market.id}/summary`);
  }
}
