import { useShipmentStore } from '@/stores';
import { Filterable } from '@/types/filter';

export default (filterable: Filterable, selected: string[]): boolean => {
  if (!selected.length) {
    return true;
  }

  return selected.includes(
    useShipmentStore().getIncotermWithLocation(filterable.shipment)
  );
};
