<template>
  <div
    class="relative flex flex-col overflow-x-hidden"
    @mouseover="pauseAutoPlay()"
    @mouseleave="resumeAutoPlay()"
  >
    <TransitionGroup
      name="slide"
      :enter-from-class="
        reverseNextAnimation ? '-translate-x-full' : 'translate-x-full'
      "
      enter-to-class="translate-x-0"
      enter-active-class="duration-500"
      leave-active-class="duration-500"
      leave-from-class="translate-x-0"
      :leave-to-class="
        reverseNextAnimation ? 'translate-x-full' : '-translate-x-full'
      "
    >
      <slot name="slides" :current-slide="currentSlide" />
    </TransitionGroup>

    <div class="mt-auto flex gap-4">
      <button
        v-for="slideNumber in slideCount"
        :key="slideNumber"
        :aria-label="`Go to slide ${slideNumber}`"
        class="mx-2 size-2 cursor-pointer rounded-full bg-slate-400"
        :class="{ 'scale-150 bg-slate-600': slideNumber === currentSlide + 1 }"
        @click="handleSlideNavigation(slideNumber - 1)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, PropType, ref } from 'vue';

const props = defineProps({
  slideCount: {
    type: Number as PropType<number>,
    required: true,
  },
});

const AUTO_PLAY_SPEED_MS = 3500;
let autoPlayTimer: NodeJS.Timeout;
const lastTimerInvocation = ref(Date.now());
const remainingTime = ref(AUTO_PLAY_SPEED_MS);

const currentSlide = ref(0);
const previousSlide = ref(0);

const reverseNextAnimation = computed(() => {
  return currentSlide.value < previousSlide.value;
});

function handleSlideNavigation(slideNumber: number) {
  if (slideNumber === currentSlide.value) {
    return;
  }

  goToSlide(slideNumber);
  restartAutoPlay();
}

function goToSlide(slideNumber: number) {
  previousSlide.value = currentSlide.value;
  currentSlide.value = slideNumber;
}

function startAutoPlay() {
  autoPlayTimer = setInterval(() => {
    goToSlide((currentSlide.value + 1) % props.slideCount);
    lastTimerInvocation.value = Date.now();
  }, AUTO_PLAY_SPEED_MS);
}

function restartAutoPlay() {
  clearInterval(autoPlayTimer);
  startAutoPlay();
}

function pauseAutoPlay() {
  clearInterval(autoPlayTimer);
  remainingTime.value =
    AUTO_PLAY_SPEED_MS -
    ((Date.now() - lastTimerInvocation.value) % AUTO_PLAY_SPEED_MS);
}

function resumeAutoPlay() {
  // One potentially faster slide change, then back to regular interval
  autoPlayTimer = setTimeout(() => {
    goToSlide((currentSlide.value + 1) % props.slideCount);
    startAutoPlay();
  }, remainingTime.value);
}

onMounted(() => {
  startAutoPlay();
});

onUnmounted(() => {
  clearInterval(autoPlayTimer);
});
</script>
