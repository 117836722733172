import { acceptedTerms, authenticated, guest } from './middleware';
import App from '@/layouts/App.vue';
import Login from '@/layouts/Login.vue';
import NoSideBar from '@/layouts/NoSideBar.vue';
import { appRoutes } from '@/router/routes';
import { captureException, withScope } from '@sentry/vue';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: App,
      beforeEnter: [authenticated],
      children: [
        {
          path: '',
          children: appRoutes,
        },
      ],
    },
    {
      path: '/',
      component: NoSideBar,
      children: [
        {
          path: '/:pathMatch(.*)*',
          name: 'not-found',
          component: () => import('@/pages/NotFound.vue'),
        },
      ],
    },
    {
      path: '/',
      component: NoSideBar,
      children: [
        {
          path: '/terms-and-conditions',
          name: 'terms-and-conditions',
          component: () => import('@/pages/TermsAndConditions.vue'),
          beforeEnter: [authenticated, acceptedTerms],
        },
      ],
    },
    {
      path: '/',
      component: Login,
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/Login.vue'),
          beforeEnter: [guest],
        },
        {
          path: 'customer-support',
          name: 'customer-support',
          component: () => import('@/pages/CustomerSupport.vue'),
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: () => import('@/pages/ForgotPassword.vue'),
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          component: () => import('@/pages/ResetPassword.vue'),
        },
        {
          path: 'password-reset-confirmed',
          name: 'password-reset-confirmed',
          component: () => import('@/pages/ResetPasswordConfirmation.vue'),
        },
      ],
    },
  ],
});

router.onError((error) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    handleImportedModuleError(error);
  }
});

function handleImportedModuleError(error: Error) {
  // On production, we only want to push a warning to Sentry so that we are notified.
  // We don't want to force a reload because the user might be in the middle of something.
  if (import.meta.env.VITE_ENV === 'production') {
    withScope((scope) => {
      scope.setLevel('warning');
      captureException(error);
    });

    return;
  }

  // On staging, we just force a reload so that the latest version of the app is loaded.
  window.location.reload();
}

export default router;
