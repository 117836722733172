<template>
  <DropdownList
    v-model="filterStore.filters[product.slug].specs"
    name="specs"
    :items="spec.options"
    multiple
    allow-empty
    :value-placeholder="$t(`product.main-spec.${product.slug}`)"
    by="description"
  >
    <template #default="{ item }">
      {{ item.description }}
    </template>
    <template #label>
      {{
        selected.length < 3
          ? selected.map((item) => item.description).join(', ')
          : $t(`filter.spec.count`, {
              spec: $t(`product.main-spec.${product.slug}`),
              count: selected.length,
            })
      }}
    </template>
  </DropdownList>
</template>
<script setup lang="ts">
import DropdownList from '@/components/DropdownList.vue';
import { useFilterStore } from '@/stores';
import { Product } from '@/types/product';
import { ProductSpec } from '@/types/product-specs';
import { computed } from 'vue';

const props = defineProps<{ product: Product; spec: ProductSpec }>();

const filterStore = useFilterStore();
const selected = computed(() => filterStore.filters[props.product.slug].specs);
</script>
