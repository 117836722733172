import { Api } from '@/api/types';
import { NegotiationPreview } from '@/types';

export class Negotiation {
  public constructor(protected readonly client: Api) {}

  public findAllByListing(listingId: number): Promise<NegotiationPreview[]> {
    return this.client.get(`/marketplace/listings/${listingId}/negotiations`);
  }

  public accept(listingId: number, negotiationId: number): Promise<void> {
    return this.client.post(
      `/marketplace/listings/${listingId}/negotiations/${negotiationId}/accept`
    );
  }

  public withdraw(listingId: number): Promise<void> {
    return this.client.post(`/marketplace/listings/${listingId}/withdraw`);
  }
}
