import { useIsBidListing, useIsOfferListing } from '@/composables/listing.ts';
import { useUserStore } from '@/stores';
import { EnrichedListingPreview } from '@/types';
import { computed } from 'vue';

export function useShowProductOrigin(listing: EnrichedListingPreview) {
  const userStore = useUserStore();

  return computed(() => {
    // If the user owns the listing, always show the product origin
    if (
      listing.isYourListing ||
      userStore.user?.company.types.includes('TRADER')
    ) {
      return true;
    }

    // If listing type is offer and the user is a buyer and doesn't own the listing, never show the product origin
    if (
      useIsOfferListing(listing) &&
      userStore.user?.company.types.includes('BUYER')
    ) {
      return false;
    }

    // Check if the listing type is 'BID'
    return useIsBidListing(listing);
  });
}
