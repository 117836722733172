import {
  useApprovedFactoryStore,
  useApprovedTraderStore,
  useCompanyFactoryStore,
  useDealStore,
  useListingStore,
  useNegotiationStore,
  usePaymentTermStore,
} from '@/stores';
import { User } from '@/types';
import { setUser as setSentryUser } from '@sentry/vue';
import { useIdle, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export type JwtPayload = { permissions: string[] };
export type UserStore = ReturnType<typeof useUserStore>;

export const INTERVAL_5_MINUTES = 5 * 60 * 1000;

export const useUserStore = defineStore('user', () => {
  const token = useStorage<string>('_auth.token', '');
  const user = ref<User>();
  const { idle: isIdle } = useIdle(INTERVAL_5_MINUTES);

  const payload = computed(() => {
    const tokenPayload = token.value.split('.')[1] ?? null;

    if (!tokenPayload) {
      return { permissions: [] };
    }

    try {
      return JSON.parse(atob(tokenPayload)) as JwtPayload;
    } catch {
      token.value = '';
      return { permissions: [] };
    }
  });

  const authenticated = computed(() => !!token.value);
  const permissions = computed(() => payload.value.permissions);
  const company = computed(() => user.value?.company);

  const hasPermission = (permission: string) =>
    permissions.value.includes(permission);
  const isMyCompany = (companyId: number) => company.value?.id == companyId;
  const isNotMyCompany = (companyId: number) => company.value?.id != companyId;
  const setTokenAndUser = (newUser: User, accessToken: string) => {
    token.value = accessToken;
    user.value = newUser;

    setSentryUser({
      id: user.value.id,
      username: `User ID: ${user.value.id}`,
      companyId: user.value.company.id,
      ip_address: '{{ auto }}',
    });
  };

  const reset = () => {
    token.value = '';
    user.value = undefined;

    setSentryUser(null);

    useListingStore().clear();
    useNegotiationStore().clear();
    useDealStore().clear();
    useApprovedFactoryStore().clear();
    useCompanyFactoryStore().clear();
    useApprovedTraderStore().clear();
    usePaymentTermStore().clear();
  };

  return {
    token,
    payload,
    user,
    company,
    authenticated,
    permissions,
    isIdle,
    hasPermission,
    isMyCompany,
    isNotMyCompany,
    setTokenAndUser,
    reset,
  };
});
