<template>
  <Carousel
    :slide-count="slideTypes.length"
    class="mx-auto flex size-full flex-col items-center justify-center p-8"
  >
    <template #slides="{ currentSlide }">
      <LoginCarouselPage
        v-for="(slideType, index) in slideTypes"
        v-show="currentSlide === index"
        :key="index"
        :slide-type="slideType"
        class="absolute flex flex-col items-center self-center"
      />
    </template>
  </Carousel>
</template>

<script setup lang="ts">
import Carousel from '@/components/Carousel.vue';
import LoginCarouselPage from '@/components/LoginCarouselPage.vue';
import { LoginSlideType } from '@/types';

const slideTypes: LoginSlideType[] = ['traders', 'sellers', 'buyers'];
</script>
