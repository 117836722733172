import { Api } from '@/api/types';
import { UserStore } from '@/stores';
import { LoginResponse, ResetPasswordRequest } from '@/types/user';

export class User {
  public constructor(
    protected readonly client: Api,
    protected readonly userStore: () => UserStore
  ) {}

  public login(email: string, password: string): Promise<LoginResponse> {
    return this.client.request('POST', 'auth/login', { email, password });
  }

  public refresh(): Promise<LoginResponse> {
    return this.client.request('POST', 'auth/refresh', undefined, {
      headers: {
        Authorization: `Bearer ${this.userStore().token}`,
      },
    });
  }

  public logout(): Promise<void> {
    return this.client.request('POST', 'auth/logout', undefined, {
      headers: {
        Authorization: `Bearer ${this.userStore().token}`,
      },
    });
  }

  public recoverPassword(email: string): Promise<void> {
    return this.client.post('auth/recover-password', {
      email: email,
    });
  }

  public resetPassword(resetRequest: ResetPasswordRequest): Promise<void> {
    return this.client.post('auth/reset-password', resetRequest, {
      headers: {
        'Referrer-Policy': 'no-referrer',
      },
    });
  }

  public acceptTermsAndConditions(version: number): Promise<void> {
    return this.client.post(`customer/terms-and-conditions/${version}/accept`);
  }
}
