import { Api } from '@/api/types';
import { CreateProposalRequest } from '@/types';

export class Proposal {
  public constructor(protected readonly client: Api) {}

  public create(create: CreateProposalRequest): Promise<void> {
    return this.client.post(
      `/marketplace/listings/${create.listingId}/negotiations/proposals`,
      {
        unitPrice: create.unitPrice,
        volume: create.volume,
      },
      {
        headers: {
          'X-Request-ID': create.uuid,
        },
      }
    );
  }

  public createAsCompany(
    create: CreateProposalRequest,
    negotiationId: number
  ): Promise<void> {
    return this.client.post(
      `/marketplace/company/listings/${create.listingId}/negotiations/${negotiationId}/proposals`,
      {
        unitPrice: create.unitPrice,
        volume: create.volume,
      },
      {
        headers: {
          'X-Request-ID': create.uuid,
        },
      }
    );
  }
}
