import { useProductStore } from '@/stores';
import { Filter, Filterable, ProductFilter } from '@/types/filter';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';

/**
 * Tip: Use function instead of the object directly.
 * It must be a function that returns a new instance everytime,
 * otherwise different products will share tha same instance, meaning
 * changing one product also will change the other (unexpected behaviour)
 */
const empty = (): Filter => ({
  specs: [],
  shipments: [],
});

export const useFilterStore = defineStore('filter', () => {
  const productStore = useProductStore();
  const filters = useStorage<ProductFilter>('filters', {});

  productStore.products.forEach((product) => {
    if (!filters.value[product.slug]) {
      /**
       * the property must exist before binding it to a form field
       * that said this statement ensure product filter exists before binding
       * to the form field
       */
      filters.value[product.slug] = empty();
    } else {
      // this is to ensure that if we add new filters the user will not lose the previous ones and it doesn't break
      filters.value[product.slug].specs ??= [];
      filters.value[product.slug].shipments ??= [];
    }
  });

  const hasActiveFilters = computed(() => {
    const filter = selectedProductFilter.value;
    return filter.specs.length > 0 || filter.shipments.length > 0;
  });

  const selectedProductFilter = computed((): Filter => {
    const product = productStore.product;

    return product ? filters.value[product.slug] : empty();
  });

  const apply = <T extends Filterable>(items: T[]): T[] => {
    const filter = selectedProductFilter.value;

    return items
      .filterProduct(productStore.product)
      .filterMainSpec(filter.specs)
      .filterShipment(filter.shipments);
  };

  const reset = () => (filters.value[productStore.product.slug] = empty());

  return {
    apply,
    filters,
    hasActiveFilters,
    reset,
    selectedProductFilter,
  };
});
