import { Api } from '@/api/types';
import { ShipmentInfo } from '@/types';

export class Shipment {
  public constructor(protected readonly client: Api) {}

  public listInfo(): Promise<ShipmentInfo> {
    return this.client.get(`/shipments`);
  }
}
