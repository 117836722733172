import { useApi } from '@/api';
import { IncotermGroup, ListingShipment, ShipmentInfo } from '@/types/shipment';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useShipmentStore = defineStore('shipment', () => {
  const api = useApi();

  const shipment = ref<ShipmentInfo>({
    locations: [],
    incoterms: [],
    dates: [],
  });

  const incotermGroups = computed<IncotermGroup[]>(() => {
    return shipment.value.incoterms.map((incoterm) => ({
      groupKey: incoterm,
      groupValues: shipment.value.locations,
    }));
  });

  const getLocationById = (id: number) => {
    return shipment.value.locations.find((location) => location.id == id);
  };

  const euLocation = computed(() => {
    if (shipment.value.locations.length === 0) {
      return null;
    }

    const location = shipment.value.locations.find(
      (location) => location.name === 'EU'
    );

    if (!location) {
      // This error is important to make sure we remember to add the EU location,
      // and also that we don't accidentally update the EU location's name.
      throw new Error('EU shipment location not found');
    }

    return location;
  });

  const getIncotermWithLocation = (shipment: ListingShipment) => {
    const location = getLocationById(shipment.locationId);

    return `${shipment.incoterm} ${location?.name ?? '...'}`;
  };

  async function load() {
    shipment.value = await api.shipment.listInfo();
  }

  return {
    euLocation,
    incotermGroups,
    load,
    getIncotermWithLocation,
    getLocationById,
    shipment,
  };
});
