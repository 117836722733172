import { useUserStore } from '@/stores';
import { ListingPreview } from '@/types';

export function useIsApprovedListing(listing: ListingPreview): boolean {
  const company = useUserStore().company;

  if (!company || company.id === listing.owner.id) {
    return false;
  }

  const matchedFactories = listing.matches[company.id] ?? [];

  return matchedFactories.length > 0;
}

export function useListingHasAvailableVolume(listing: ListingPreview): boolean {
  return listing.volume.amount > 0;
}

export function useIsBidListing(listing: ListingPreview): boolean {
  return listing.type === 'BID';
}

export function useIsOfferListing(listing: ListingPreview): boolean {
  return listing.type === 'OFFER';
}

export function useIsPublishedListing(listing: ListingPreview): boolean {
  return listing.status === 'PUBLISHED';
}

export function useIsOngoingListing(listing: ListingPreview): boolean {
  return listing.stats.totalOngoing > 0;
}

export function useIsNotOngoingListing(listing: ListingPreview): boolean {
  return !useIsOngoingListing(listing);
}
