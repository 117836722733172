import { factorySchema } from '@/schemas/company';
import { productSchema } from '@/schemas/product';
import {
  deliveryLocationSchema,
  incotermSchema,
  latestDeliverySchema,
} from '@/schemas/shipment';
import { z } from 'zod';

export const listingTypes = ['BID', 'OFFER'] as const;
export const listingTypeSchema = z.enum(listingTypes);

export const listingAudiences = ['ANY_PARTY', 'DIRECT_PARTY'] as const;
export const listingAudienceSchema = z.enum(listingAudiences);

export const groupedFactoryOptionSchema = z.object({
  groupKey: z.string(),
  value: factorySchema,
});

export const createListingFormSchema = z.object({
  product: productSchema,
  type: listingTypeSchema,
  unitPriceValue: z.number({ message: 'Price must be a number' }),
  volumeAmount: z.number({ message: 'Volume must be a number' }),
  incoterm: incotermSchema,
  deliveryLocation: deliveryLocationSchema,
  latestDelivery: latestDeliverySchema,
  selectedFactories: z
    .array(groupedFactoryOptionSchema)
    .min(1, { message: 'At least one factory must be selected' }),
});
