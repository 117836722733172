import { useProductStore } from './product';
import { useApi } from '@/api';
import { Market } from '@/types/market';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useMarketStore = defineStore('market', () => {
  const api = useApi();
  const productStore = useProductStore();

  const markets = ref<Market[]>([]);
  const market = computed(() => {
    const productId = productStore.product?.id;

    return (
      markets.value.find((market) => market.productId === productId) || null
    );
  });

  const nextMarket = computed(() => {
    const productId = productStore.product?.id;

    return markets.value.find(
      (market) =>
        (market.productId === productId && market.status === 'SCHEDULED') ||
        null
    );
  });

  async function load() {
    markets.value = await api.market.list();
  }

  const marketOpen = computed(() => market.value?.status === 'OPEN');
  const dateOfReference = computed(() => {
    if (market.value === undefined) return new Date();

    return new Date(
      market.value?.status === 'OPEN'
        ? market.value!.closeAt
        : market.value!.startAt
    );
  });

  return {
    load,
    markets,
    market,
    marketOpen,
    nextMarket,
    dateOfReference,
  };
});
