<template>
  <template v-for="(part, index) of parts" :key="part">
    <template v-if="index % 2 === 0">{{ part }}</template>
    <slot v-else :name="part.trim()" />
  </template>
</template>

<script setup lang="ts">
import { useI18n } from '@/translations';
import i18next, { TOptions } from 'i18next';
import { computed, PropType } from 'vue';

const props = defineProps({
  path: {
    type: String,
    required: true,
  },
  options: {
    type: Object as PropType<TOptions>,
    default: () => ({}),
  },
});

const { t } = useI18n();
const parts = computed(() => {
  const { regexp } = i18next.services.interpolator as unknown as {
    regexp: RegExp;
  };
  return t(props.path, props.options).split(regexp);
});
</script>
