import { NegotiationPaused } from '@/notifications/types';
import { useNegotiationStore, useProductStore, useToastStore } from '@/stores';
import { t } from 'i18next';

const pauseNegotiation = (event: NegotiationPaused) => {
  useNegotiationStore().pause({
    listingId: event.listingId,
    negotiationId: event.negotiationId,
  });
};

const negotiationPausedNotification = (event: NegotiationPaused) => {
  const product = useProductStore().findById(event.productId);

  if (!product) {
    return;
  }

  useToastStore().success({
    title: t('notifications.negotiation-paused.title'),
    message: t('notifications.negotiation-paused.message', {
      value: event.availableVolume,
      type: t(`common.${event.listingType}`),
      listing: event.listingId,
    }),
    link: `/overview/products/${product.slug}/listings/${event.listingId}`,
    timeout: 7000,
  });
};

export default [pauseNegotiation, negotiationPausedNotification];
