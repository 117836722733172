import { Api } from '@/api/types';
import { Product as ProductResponse } from '@/types/product';
import { CategorisedProductSpec } from '@/types/product-specs';

export class Product {
  public constructor(protected readonly client: Api) {}

  public list(): Promise<ProductResponse[]> {
    return this.client.get('/marketplace/products');
  }

  public specsOf(id: number): Promise<CategorisedProductSpec> {
    return this.client.get(`/marketplace/products/${id}/specifications`);
  }
}
