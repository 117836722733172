import { companyEventsHandlers } from '@/notifications/company';
import { generalEventsHandlers } from '@/notifications/general';
import { EventHandler, Handlers } from '@/types/notification';
import { defineStore } from 'pinia';

export const useBroadcastStore = defineStore('broadcasts', {
  state: () => ({}),
  actions: {
    general(event: string, payload: object): void {
      this.handleNotification(
        generalEventsHandlers as Handlers<EventHandler>,
        event,
        payload
      );
    },

    company(event: string, payload: object): void {
      this.handleNotification(
        companyEventsHandlers as Handlers<EventHandler>,
        event,
        payload
      );
    },

    handleNotification<T>(
      handlers: Handlers<T>,
      event: string,
      payload: object
    ): void {
      const eventHandlers = handlers[event];

      if (eventHandlers == undefined) {
        return;
      }

      eventHandlers.forEach((handler) => handler(payload as T));
    },
  },
});
