import { z } from 'zod';

export const productSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  priceRange: z.object({
    min: z.object({
      value: z.string(),
      currency: z.string(),
    }),
    max: z.object({
      value: z.string(),
      currency: z.string(),
    }),
  }),
  volumeRange: z.object({
    min: z.object({
      amount: z.string(),
      unit: z.string(),
    }),
    max: z.object({
      amount: z.string(),
      unit: z.string(),
    }),
  }),
});
