import { useUserStore } from '@/stores';
import { defineStore } from 'pinia';

export const useTermsAndConditionsStore = defineStore(
  'termsAndConditions',
  () => {
    const version = 1;

    const userHasValidVersion = () => {
      const userStore = useUserStore();

      const acceptedVersion = userStore.user?.termsAndConditionsVersion ?? 0;

      return acceptedVersion >= version;
    };

    return {
      version,
      userHasValidVersion,
    };
  }
);
