import { Api } from '@/api/types';
import { PaymentTerm as PaymentTermType } from '@/types';

export class PaymentTerm {
  public constructor(protected readonly client: Api) {}

  public list(): Promise<Record<number, PaymentTermType>> {
    return this.client.get(`/customer/payment-terms`);
  }
}
