import { useToastStore } from '@/stores/toast';
import { DomainError } from '@/types/errors';
import { t } from 'i18next';
import { defineStore } from 'pinia';

/**
 * Errors listed here will not be handled by the generic error handler.
 * if you implement any try/catch or then(...).catch(...) then you can add the error
 * to this list.
 *
 * All generic errors are bypassed by the handler, meaning they are both shown
 * as a toast message and logged into sentry.
 *
 * Skipped errors are also thrown and should be handled elsewhere, otherwise
 * they will be handled by sentry
 */
const skipGenericHandler: string[] = [
  // ... add the error domain code here to skip the generic handler
  // then create your try catch whenever the action gets executed
];

export const useErrorStore = defineStore('error', () => {
  const toastStore = useToastStore();

  const handleError = (error: DomainError) => {
    const code = error.code ?? 'UNKNOWN';

    if (skipGenericHandler.includes(code)) {
      return;
    }

    toastStore.error({
      title: t('toast.error'),
      message: t(`errors.${code}.message`),
      timeout: 0,
    });

    handleDebugError(error);
  };

  const handleDebugError = (error: DomainError) => {
    if (import.meta.env.VITE_ENV !== 'development') {
      return;
    }

    const debug = error.rules ? error.rules[0] : error.debug?.message;

    if (!debug) {
      return;
    }

    toastStore.warning({
      title: t('toast.debug'),
      message: debug,
      timeout: 0,
    });
  };

  return {
    handleError,
  };
});
