import { usePusher } from '@/broadcasts/pusher';
import { useBroadcastStore, useUserStore } from '@/stores';

export function initialisePusher() {
  if (import.meta.env.VITE_PUSHER_APP_KEY) {
    const userStore = useUserStore();
    const user = userStore.user;

    if (!user) {
      return;
    }

    const broadcast = useBroadcastStore();

    usePusher().subscribe('private-general').bind_global(broadcast.general);

    usePusher()
      .subscribe(`private-company-${user.company.id}`)
      .bind_global(broadcast.company);
  }
}
