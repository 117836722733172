import { useI18n } from '@/translations';
import { Plugin } from 'vue';

const plugin: Plugin = {
  install(app) {
    const { t, format } = useI18n();

    app.config.globalProperties.$t = t;
    app.config.globalProperties.$format = format;
  },
};

export default plugin;
