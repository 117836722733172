import { useApi } from '@/api';
import { useUserStore } from '@/stores';
import {
  BuyerApprovedFactory,
  Factory,
  ListingAudience,
  Product,
} from '@/types';
import { captureException, withScope } from '@sentry/vue';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useApprovedFactoryStore = defineStore('approvedFactory', () => {
  const api = useApi();
  const buyerApprovedFactories = ref<BuyerApprovedFactory[]>([]);
  const approvedFactories = computed<Factory[]>(() =>
    buyerApprovedFactories.value.map(
      (approvedFactory) => approvedFactory.factory
    )
  );

  async function load() {
    if (!useUserStore().hasPermission('CUSTOMER/APPROVED_FACTORY.GET')) {
      return;
    }

    await api.company
      .listApprovedFactories()
      .then(
        (loadedFactories) => (buyerApprovedFactories.value = loadedFactories)
      )
      .catch((error) =>
        withScope((scope) => {
          scope.setLevel('warning');
          captureException(
            new Error('Failed to load approved factories', { cause: error })
          );
        })
      );
  }

  function filterByProductAndAudience(
    product: Product,
    audience: ListingAudience
  ) {
    return buyerApprovedFactories.value
      .filter((approvedFactory) => {
        const { negotiationType, approvedProducts } = approvedFactory;

        const hasProduct = approvedProducts.includes(product.id);

        const isAudienceMatch =
          audience === 'ANY_PARTY' ||
          (audience === 'DIRECT_PARTY' && negotiationType !== 'INDIRECT');

        return hasProduct && isAudienceMatch;
      })
      .map((approvedFactory) => approvedFactory.factory); // Map to get the factory details only
  }

  function clear() {
    buyerApprovedFactories.value = [];
  }

  return {
    approvedFactories,
    buyerApprovedFactories,
    clear,
    filterByProductAndAudience,
    load,
  };
});
