<template>
  <div class="flex items-center space-x-3">
    <MarketFilterSpec v-if="product && spec" :product="product" :spec="spec" />
    <MarketFilterShipment :product="product" />

    <span
      v-if="filterStore.hasActiveFilters"
      class="text-sm/6 text-slate-500 hover:cursor-pointer hover:text-indigo-950"
      @click="filterStore.reset()"
    >
      {{ $t('filter.clear') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import MarketFilterShipment from '@/components/MarketFilterShipment.vue';
import MarketFilterSpec from '@/components/MarketFilterSpec.vue';
import { useComputedAsyncState } from '@/composables';
import { useFilterStore, useProductStore } from '@/stores';
import { Product } from '@/types';

const filterStore = useFilterStore();
const productStore = useProductStore();

const props = defineProps<{ product: Product }>();

const { state: spec } = useComputedAsyncState(() => {
  return productStore.mainSpecOf(props.product);
}, null);
</script>
