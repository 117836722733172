import { isNull } from 'lodash';
import Pusher from 'pusher-js';
import { ChannelAuthorizationOptions } from 'pusher-js/src/core/auth/options';

Pusher.logToConsole = import.meta.env.VITE_PUSHER_DEBUG === 'true';

let instance: Pusher | null = null;

export const usePusher = (): Pusher => {
  const channelAuthorization: ChannelAuthorizationOptions = {
    transport: 'ajax',
    endpoint: `${import.meta.env.VITE_API_BASE_URL}/notification/broadcast/auth/channel`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('_auth.token')}`,
    },
  };

  if (isNull(instance)) {
    instance = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      forceTLS: true,
      channelAuthorization: channelAuthorization,
    });
  }

  return instance;
};

export const disconnectPusher = (): void => {
  if (!isNull(instance)) {
    instance.disconnect();
    instance = null;
  }
};
