import { useApi } from '@/api';
import { useListingStore, withoutStats } from '@/stores';
import { ListingPreview } from '@/types';

const isActive = (listing: ListingPreview) => {
  const listingStore = useListingStore();

  return listingStore.isActive(listing) && listingStore.isAvailable(listing);
};

export default <E>(event: E & { listing: ListingPreview }) => {
  const listing = event.listing;

  if (!isActive(listing)) {
    return useListingStore().addOrUpdate(withoutStats(listing));
  }

  /**
   * Use events to fetch listings might lead to overloading the system,
   * however stats are computed to the current user and computing in the backend
   * for each possible user will add a huge complexity to backend process.
   *
   * USE WHEN: Listing data has to be computed for the specific user
   * DO NOT USE WHEN: update the listing for users not directly interested on listing stats
   */
  useApi()
    .listing.getPreviewById(event.listing.id)
    .then((listing) => {
      useListingStore().addOrUpdate(listing);
    });
};
