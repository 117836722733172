import { Api } from '@/api/types';
import { ApprovedTrader, BuyerApprovedFactory, Factory } from '@/types/company';

export class Company {
  public constructor(protected readonly client: Api) {}

  public listFactories(): Promise<Factory[]> {
    return this.client.get('customer/factories');
  }

  public listApprovedFactories(): Promise<BuyerApprovedFactory[]> {
    return this.client.get('customer/approved-factories');
  }

  public listApprovedTraders(): Promise<ApprovedTrader[]> {
    return this.client.get('customer/approved-traders');
  }
}
