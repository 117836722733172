<template>
  <Teleport to="body">
    <Transition name="toast">
      <div
        v-if="toastStore.toasts.length"
        class="fixed right-5 top-20 z-50 flex flex-col space-y-4"
      >
        <TransitionGroup
          name="toast"
          tag="div"
          class="flex flex-col-reverse space-y-4 space-y-reverse"
          enter-from-class="transform translate-x-full opacity-0"
          leave-to-class="transform translate-x-full opacity-0"
          enter-active-class="transition duration-450 ease-in-out"
          leave-active-class="transition duration-450 ease-in-out"
        >
          <template v-for="toast in toastStore.toasts" :key="toast.message">
            <RouterLink
              :to="{ path: toast.link }"
              class="flex min-w-72 rounded-md bg-white shadow-md"
            >
              <div class="flex space-x-2">
                <div
                  class="grid place-content-center rounded-s-md px-1.5"
                  :class="getStyle(toast.status)"
                >
                  <div
                    class="grid size-4 place-content-center rounded-full bg-white"
                  >
                    <Icon
                      :icon="getIcon(toast.status)"
                      fixed-width
                      class="text-sm"
                    />
                  </div>
                </div>
                <div class="py-3">
                  <span>{{ toast.title }}</span>
                  <div>
                    <span>{{ toast.message }}</span>
                  </div>
                </div>
              </div>
              <div class="ml-auto">
                <button
                  class="rounded-md text-sm"
                  @click="toastStore.removeToast(toast.id)"
                >
                  <Icon icon="fa-regular fa-xmark" fixed-width />
                </button>
              </div>
            </RouterLink>
          </template>
        </TransitionGroup>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { useToastStore } from '@/stores';
import { ToastStatus } from '@/types';

const toastStore = useToastStore();

const getStyle = (status: ToastStatus) => {
  const styleMap = {
    SUCCESS: 'bg-green-500 text-green-500',
    ERROR: 'bg-red-500 text-red-500',
    WARNING: 'bg-yellow-500 text-yellow-500',
    INFO: 'bg-blue-500 text-blue-500',
    default: 'bg-blue-500 text-blue-500',
  };
  return styleMap[status] || styleMap.default;
};

const getIcon = (status: ToastStatus) => {
  const iconMap = {
    SUCCESS: 'fa-solid fa-check',
    ERROR: 'fa-solid fa-xmark',
    WARNING: 'fa-solid fa-exclamation',
    INFO: 'fa-solid fa-info',
    default: 'fa-solid fa-info',
  };
  return iconMap[status] || iconMap.default;
};
</script>
