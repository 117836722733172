import Base from '@/api/base';
import { Api } from '@/api/types';
import { useLogout } from '@/composables';
import { useErrorStore, useUserStore } from '@/stores';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
const TIMEOUT_DURATION_2_MINUTES = 120 * 1000;

const createVesperHttpClient = (options = {}): AxiosInstance => {
  const errorStore = useErrorStore();
  const userStore = useUserStore();
  const { logout } = useLogout();

  const client = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout: TIMEOUT_DURATION_2_MINUTES,
    headers: {
      'X-APP-VERSION': '1',
      'X-Transaction-ID': Math.random().toString(36).substring(2, 9),
    },
    ...options,
  });

  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError | null) => {
      if (error?.status === 401 && userStore.token) {
        await logout();
      } else {
        errorStore.handleError(error?.response?.data ?? {});
      }

      return Promise.reject(error);
    }
  );

  return client;
};

export const createVesperApiClient = (): Api => {
  return new Base(createVesperHttpClient(), () => useUserStore());
};
