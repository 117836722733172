<template>
  <div
    class="grid place-content-center rounded-md px-2 text-xs/5 font-semibold"
  >
    {{ `${memory} MB` }}
  </div>
</template>
<script setup lang="ts">
import { MemoryInfo } from '@vueuse/core';
import { ref } from 'vue';

const memory = ref('0');

type BrowserPerformance = Performance & { memory: MemoryInfo };
const convert = (value: number) => (value / 1000000).toFixed(2);

setInterval(() => {
  const performance = window.performance as BrowserPerformance;
  memory.value = convert(performance.memory.usedJSHeapSize);
}, 1000);
</script>
