import { z } from 'zod';

export const productSpecOptionSchema = z.object(
  {
    id: z.number(),
    description: z.string(),
  },
  { message: 'Please select an option' }
);

export const selectedSpecSchema = z.object({
  description: z.string(),
  options: z.array(productSpecOptionSchema),
});
