import {
  useIsApprovedListing,
  useIsBidListing,
  useIsNotOngoingListing,
  useIsOfferListing,
  useIsPublishedListing,
  useListingHasAvailableVolume,
} from '@/composables';
import { useFilterStore, useUserStore } from '@/stores';
import { EnrichedListingPreview, ListingPreview } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const withoutStats = (listing: ListingPreview) => {
  listing.stats = {
    totalOngoing: 0,
    waitingResponse: 0,
    lowestCounterOffer: undefined,
    highestCounterBid: undefined,
  };

  return listing;
};

export const useListingStore = defineStore('listings', () => {
  const activeHistory = ref<Record<number, number>>({});
  const listings = ref<EnrichedListingPreview[]>([]);
  const userStore = useUserStore();
  const filtered = computed(() => useFilterStore().apply(listings.value));

  const enriched = (listing: ListingPreview): EnrichedListingPreview => {
    return {
      ...listing,
      isYourListing: userStore.isMyCompany(listing.owner.id),
    };
  };

  const setListings = (list: ListingPreview[]) => {
    listings.value = list.map((listing) => enriched(listing));
    computeActiveHistory();
  };

  const updateListings = (list: ListingPreview[]) => {
    list.reverse().forEach((listing) => addOrUpdate(listing));
  };

  const addOrUpdate = (listing: ListingPreview) => {
    if (listing.volume.amount <= 0) {
      /**
       * When the listing gets fulfilled, it will be removed from the store by ListingCompleted handlers.
       * Since there is no guarantee which event will be handled first, this if avoids adding to the store
       * again when the listing was previously removed.
       */
      return;
    }

    const index = listings.value.findIndex((item) => item.id === listing.id);
    index >= 0
      ? (listings.value[index] = enriched(listing))
      : /**
         * not entirely sure if we should just prepend the missing item or run a
         * sorting function -> listings.value.sort((a, b) => b.id - a.id);
         */
        listings.value.unshift(enriched(listing));

    computeActiveHistory();
  };

  const clear = () => {
    listings.value = [];
    activeHistory.value = {};
  };

  const computeActiveHistory = () => {
    active.value.forEach((listing) => {
      activeHistory.value[listing.id] = listing.id;
    });
  };

  const completeListing = (listing: { id: number }) => {
    const index = listings.value.findIndex((item) => item.id === listing.id);

    if (index < 0) {
      // If the listing is not found, then the user likely already refreshed the store before receiving the event.
      return;
    }

    const listingEntry = listings.value[index];
    listingEntry.status = 'COMPLETED';
    listingEntry.volume.amount = 0;
    listings.value[index] = enriched(withoutStats(listingEntry));
  };

  const cancelListing = (listing: { id: number }) => {
    const index = listings.value.findIndex((item) => item.id === listing.id);

    if (index < 0) {
      // If the listing is not found, then the user likely already refreshed the store before receiving the event.
      return;
    }

    const listingEntry = listings.value[index];
    listingEntry.status = 'CANCELED';
    listings.value[index] = enriched(withoutStats(listingEntry));
  };

  const remove = (listing: { id: number }) => {
    listings.value = listings.value.filter((item) => item.id !== listing.id);
  };
  const removeFromActiveHistory = (listing: { id: number }) => {
    delete activeHistory.value[listing.id];
  };

  const withdraw = (listing: ListingPreview) => {
    delete activeHistory.value[listing.id];
    removeFromActiveHistory(listing);
    addOrUpdate(withoutStats(listing));
  };

  const isAvailable = (listing: ListingPreview) => {
    return (
      useListingHasAvailableVolume(listing) && useIsPublishedListing(listing)
    );
  };
  const bestPriceOf = (listing: EnrichedListingPreview) => {
    const best = useIsBidListing(listing)
      ? // If the bid is mine, the best I can receive is the lowestCounterOffer.
        listing.isYourListing
        ? listing.stats.lowestCounterOffer
        : listing.stats.highestCounterBid
      : // If the offer is mine, the best I can receive is the highestCounterBid.
        listing.isYourListing
        ? listing.stats.highestCounterBid
        : listing.stats.lowestCounterOffer;

    return best ?? listing.unitPrice;
  };

  const active = computed(() => {
    return filtered.value.filter((listing) => {
      const ongoing = listing.stats.totalOngoing > 0;
      const history = activeHistory.value[listing.id] === listing.id;

      return listing.isYourListing || ongoing || history;
    });
  });

  const isActive = (listing: ListingPreview) => {
    return active.value.some((item: ListingPreview) => {
      return item.id === listing.id;
    });
  };

  const opportunities = computed(() => {
    return filtered.value
      .filter(useIsApprovedListing)
      .filter(useIsNotOngoingListing)
      .filter((listing) => activeHistory.value[listing.id] == undefined);
  });

  const bids = computed(() => filtered.value.filter(useIsBidListing));
  const offers = computed(() => filtered.value.filter(useIsOfferListing));
  const all = computed(() => filtered.value);

  const publishedBidCount = computed(() => {
    return bids.value.filter(useIsPublishedListing).length;
  });
  const publishedOfferCount = computed(() => {
    return offers.value.filter(useIsPublishedListing).length;
  });

  return {
    active,
    addOrUpdate,
    all,
    bestPriceOf,
    bids,
    cancelListing,
    clear,
    completeListing,
    isActive,
    isAvailable,
    listings: filtered,
    offers,
    opportunities,
    publishedBidCount,
    publishedOfferCount,
    remove,
    removeFromActiveHistory,
    setListings,
    updateListings,
    withdraw,
    enriched,
  };
});
