import { useApi } from '@/api';
import { ApprovedTrader, Owner } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useApprovedTraderStore = defineStore('approvedTrader', () => {
  const api = useApi();
  const approvedTraders = ref<ApprovedTrader[]>([]);
  const traderIds = computed(() =>
    approvedTraders.value.map((trader) => trader.id)
  );

  async function load() {
    approvedTraders.value = await api.company.listApprovedTraders();
  }

  function clear() {
    approvedTraders.value = [];
  }

  const isApprovedTrader = (owner: Owner): boolean => {
    return traderIds.value.includes(owner.id);
  };

  return {
    isApprovedTrader,
    clear,
    load,
  };
});
