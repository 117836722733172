import { useToastStore } from '@/stores';
import { captureException, withScope } from '@sentry/vue';

const handledKeys: string[] = [];

export function missingTranslationKeyHandler(
  lngs: readonly string[],
  ns: string,
  key: string,
  fallbackValue: string
) {
  if (handledKeys.includes(key)) {
    return fallbackValue;
  }

  const errorTitle = 'Missing translation';
  const errorMessage = `Key: "${key}" for language "${lngs}" not found in namespace "${ns}".`;

  if (import.meta.env.DEV) {
    useToastStore().warning({
      title: errorTitle,
      message: errorMessage,
      timeout: 0,
    });
  }

  withScope((scope) => {
    scope.setLevel('warning');
    captureException(new Error(errorMessage));
  });

  handledKeys.push(key);

  return fallbackValue;
}
