import { Interval } from '@/api/types';
import { useUserStore } from '@/stores';
import { useAsyncState } from '@vueuse/core';
import { computed, Ref, watch } from 'vue';

export const INTERVAL_30_SECONDS = 30 * 1000;
const polls: Record<string, Interval> = {};

export const usePollingState = <T>(
  key: string,
  promise: () => Promise<T>,
  enabled: Ref<boolean>,
  interval: number = INTERVAL_30_SECONDS
) => {
  const userStore = useUserStore();
  const active = computed(() => !userStore.isIdle && enabled.value);
  const controller = useAsyncState(promise, undefined as T, {
    immediate: enabled.value,
    resetOnExecute: false,
  });

  const restart = async () => {
    clearInterval(polls[key]);

    if (active.value) {
      polls[key] = setInterval(controller.execute, interval);
      await controller.execute();
    }
  };

  watch(active, restart);

  return {
    state: controller.state,
    error: controller.error,
    restart,
    isReady: controller.isReady,
    isLoading: controller.isLoading,
  };
};

export const clearPolls = () => {
  Object.keys(polls).forEach((key) => clearInterval(polls[key]));
};
