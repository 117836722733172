<template>
  <input
    :id="id"
    v-model="computedValue"
    type="radio"
    :name="name"
    :disabled="disabled"
    :value="value"
    :checked="checked"
    :required="required"
    class="peer size-px opacity-0"
  />

  <Icon :icon="icon" :class="$attrs.class" />
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { computed, PropType } from 'vue';

const props = defineProps({
  modelValue: {
    type: null,
    default: undefined,
  },
  value: {
    type: null,
    default: undefined,
  },
  checked: {
    type: Boolean,
    default: undefined,
  },
  id: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<'outline' | 'solid'>,
    default: 'outline',
  },
});

const emit = defineEmits(['update:modelValue']);

const computedValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const isChecked = computed(() => {
  if (props.checked !== undefined) {
    return props.checked;
  }
  if (props.modelValue instanceof Array) {
    return props.modelValue.includes(props.value);
  }
  return props.modelValue === props.value;
});

const icon = computed(() => {
  const state = isChecked.value ? 'fa-circle-dot' : 'fa-circle';
  const style =
    props.variant === 'solid' && isChecked.value ? 'fa-solid' : 'fa-regular';

  return `${style} ${state}`;
});
</script>
