import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePanelCreateListingStore = defineStore(
  'panelCreateListing',
  () => {
    const panelIsOpen = ref(false);

    const openPanel = () => {
      panelIsOpen.value = true;
    };

    const closePanel = () => {
      panelIsOpen.value = false;
    };

    const togglePanel = () => {
      panelIsOpen.value = !panelIsOpen.value;
    };

    return {
      closePanel,
      openPanel,
      panelIsOpen,
      togglePanel,
    };
  }
);
