import { useApi } from '@/api';
import { PaymentTerm } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePaymentTermStore = defineStore('paymentTerm', () => {
  const api = useApi();
  const paymentTerms = ref<Record<number, PaymentTerm>>({});

  async function load() {
    paymentTerms.value = await api.paymentTerm.list();
  }

  function clear() {
    paymentTerms.value = {};
  }

  return {
    clear,
    load,
    paymentTerms,
  };
});
