import { NegotiationPreview } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

type ListingId = number;

export const useNegotiationStore = defineStore('negotiations', () => {
  const negotiations = ref<Record<ListingId, NegotiationPreview[]>>({});

  const negotiationExists = (listingId: number, negotiationId: number) => {
    return negotiations.value[listingId]?.some(
      (negotiation) => negotiation.id === negotiationId
    );
  };

  const remove = (negotiation: {
    listingId: number;
    negotiationId: number;
  }) => {
    if (!negotiationExists(negotiation.listingId, negotiation.negotiationId)) {
      return;
    }

    negotiations.value[negotiation.listingId] = negotiations.value[
      negotiation.listingId
    ].filter((stored) => {
      return stored.id != negotiation.negotiationId;
    });
  };

  const pause = (negotiation: { listingId: number; negotiationId: number }) => {
    if (!negotiationExists(negotiation.listingId, negotiation.negotiationId)) {
      return;
    }

    const negotiationIndex = negotiations.value[
      negotiation.listingId
    ].findIndex((item) => item.id === negotiation.negotiationId);
    const pausedNegotiation =
      negotiations.value[negotiation.listingId][negotiationIndex];

    pausedNegotiation.status = 'PAUSED';

    negotiations.value[negotiation.listingId][negotiationIndex] =
      pausedNegotiation;
  };

  function clear() {
    negotiations.value = {};
  }

  return {
    clear,
    negotiations,
    pause,
    remove,
  };
});
