<template>
  <RouterLink :to="to" class="group flex items-center text-sm">
    <div
      class="relative flex size-7 shrink-0 items-center justify-center rounded-md text-indigo-950 group-hover:bg-slate-300"
      :class="{
        'bg-slate-300': routeMatched,
        'mr-[23px]': showSidebar,
      }"
    >
      <Icon v-if="iconLeft" :icon="iconLeft" fixed-width />
    </div>
    <div
      v-if="showSidebar"
      data-test="menu-item-text"
      class="text-indigo-950/50 group-hover:text-indigo-950"
      :class="{
        'text-indigo-950': routeMatched,
      }"
    >
      <slot />
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { useProductStore } from '@/stores';
import { computed, PropType } from 'vue';
import { RouteLocationNamedRaw, useRoute } from 'vue-router';

const props = defineProps({
  to: {
    type: Object as PropType<RouteLocationNamedRaw>,
    required: false,
    default: null,
  },
  iconLeft: {
    type: String,
    default: '',
  },
  showSidebar: {
    type: Boolean,
    required: true,
  },
  classes: {
    type: Array as PropType<string[]>,
    required: false,
    default: null,
  },
});

const route = useRoute();
const productStore = useProductStore();

const routeMatched = computed(() =>
  // Check the entire tree of what caused the current route to be activated. If any of the names match the name of the
  // route this menuItem will navigate to, it is considered "active"
  route.matched.some(({ name }) => {
    // If the route is 'overview' or 'listings', we need to check the product slug in the route params
    if ((name === 'overview' || name === 'listings') && props.to.params) {
      return productStore.product.slug === props.to.params.productSlug;
    }

    // Props.to is of type RouteLocationRaw. In our case, it always has a 'name' prop; see mainNavItems in AppSideBarNav.vue
    const toName = (props.to as { name: string }).name;
    return name === toName;
  })
);
</script>
