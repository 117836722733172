<template>
  <Component
    :is="variant[0] === 'button' ? DropdownPanel : 'div'"
    class="flex max-h-96 w-72 min-w-[180px] flex-col focus:outline-none"
  >
    <div v-if="searchable" class="flex items-center border-b">
      <ComboboxInput
        :display-value="() => searchQuery"
        :placeholder="
          searchInputPlaceholder ||
          $t('categorised-dropdown-body-inline.search-placeholder', {
            type: categoryGroupingLabel,
          })
        "
        class="block flex-1 bg-transparent px-4 py-3 text-sm/none placeholder:text-vesper-blue/50 focus:outline-none"
        :class="{ 'bg-slate-100': variant[1] === 'dark' }"
        @change="searchQuery = $event.target.value"
      />
      <div
        class="mr-6 flex size-8 rounded-full bg-slate-100 text-xs text-vesper-blue/50"
      >
        <Icon icon="fa-regular fa-magnifying-glass" class="m-auto" />
      </div>
    </div>
    <div
      v-if="max"
      class="border-b px-6 py-3 text-xs/none text-vesper-blue/50"
      :class="{ 'bg-slate-100': variant[1] === 'dark' }"
    >
      <Translation
        path="categorised-dropdown-body-inline.max"
        :options="{
          type: categoryGroupingLabel,
        }"
      >
        <template #amount>
          <span class="font-semibold text-vesper-blue">
            {{ $format(max, 'number') }}
          </span>
        </template>
      </Translation>
    </div>
    <div v-if="filteredItems.length" class="flex-1 overflow-auto">
      <Component
        :is="searchable ? ComboboxOption : ListboxOption"
        v-for="(item, index) in filteredItems"
        :key="index"
        v-slot="{ active, selected }"
        as="template"
        :value="item"
        :disabled="isDisabled(item)"
      >
        <div
          class="flex cursor-pointer items-center py-2 text-xs hover:text-vesper-neutral focus:outline-none"
          :class="{
            'pl-4 pr-6': variant[0] === 'button',
            'pl-6 pr-8': variant[0] === 'inline',
            'bg-vesper-zinc text-vesper-neutral': active,
          }"
        >
          <slot :item="item">
            {{ item }}
          </slot>
          <span class="ml-auto pl-4">
            <Checkbox
              v-if="multiple || allowEmpty"
              :checked="selected"
              :disabled="isDisabled(item)"
              class="text-sm peer-checked:text-vesper-neutral peer-disabled:text-vesper-blue/50"
              variant="solid"
            />
            <Radiobutton
              v-else
              :checked="selected"
              :disabled="isDisabled(item)"
              class="text-sm peer-checked:text-vesper-neutral"
            />
          </span>
        </div>
      </Component>
    </div>
    <div v-else class="py-4 text-center text-sm/none text-vesper-blue/50">
      {{ $t('common.no-matches-found') }}
    </div>
  </Component>
</template>

<script setup lang="ts">
import Checkbox from '@/components/Checkbox.vue';
import DropdownPanel from '@/components/DropdownPanel.vue';
import Icon from '@/components/Icon.vue';
import Radiobutton from '@/components/Radiobutton.vue';
import Translation from '@/components/Translation.vue';
import { useDropdown } from '@/composables';
import { ComboboxInput, ComboboxOption, ListboxOption } from '@headlessui/vue';
import { PropType } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  categoryGroupingLabel: {
    type: [String, Array] as PropType<string | string[]>,
    default: 'common.products',
  },
  searchInputPlaceholder: {
    type: String,
    default: '',
  },
  modelValue: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: [Object, Array, String, Number] as PropType<any | any[]>,
    default: () => [],
  },
  items: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as PropType<any[]>,
    default: () => [],
  },
  dropdownTestHandle: {
    type: String,
    default: null,
  },
  allowEmpty: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  searchProps: {
    type: Array as PropType<string[]>,
    default: () => ['name', 'slug'],
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: Array as unknown as PropType<
      readonly ['inline' | 'button', 'dark' | 'light']
    >,
    required: true,
  },
  max: {
    type: Number,
    default: null,
  },
});

const { searchQuery, filteredItems, isDisabled } = useDropdown(
  props,
  emit,
  (item, query) => {
    if (typeof item === 'string') {
      return item.toLowerCase().includes(query);
    }

    return props.searchProps.some((prop) => {
      return (
        typeof item[prop] === 'string' &&
        item[prop].toLowerCase().includes(query)
      );
    });
  }
);
</script>
