import { usePaymentTermStore } from '@/stores';
import { Owner } from '@/types';
import { computed, ComputedRef } from 'vue';

export function usePaymentTermDuration(
  counterParty: Owner
): ComputedRef<number> | null {
  const paymentTermStore = usePaymentTermStore();
  return computed(() => {
    return paymentTermStore.paymentTerms[counterParty.id]?.duration ?? null;
  });
}
