import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('sidebar', {
  state: () => {
    const sidebarPinned = localStorage.getItem('sidebar-pinned');
    const isSidebarPinned = sidebarPinned ? sidebarPinned !== 'false' : false;

    return {
      showMobile: false,
      sidebarPinned: isSidebarPinned,
      lastIntersectedId: '',
    };
  },
  actions: {
    toggle() {
      this.showMobile = !this.showMobile;
    },
    deactivate() {
      this.showMobile = false;
    },
    setLastIntersectedId(widgetId: string) {
      this.lastIntersectedId = widgetId;
    },
  },
});
