import { DealCreated } from '@/notifications/types';
import {
  useDealModalStore,
  useDealStore,
  useListingStore,
  useNegotiationStore,
} from '@/stores';

const dealCreatedNotification = (event: DealCreated) => {
  useDealModalStore().showModal(event);
};

const addCompanyDeal = (event: DealCreated) => {
  useDealStore().addCompanyDeal(event.deal);
};

const addToTradedDeals = (event: DealCreated) => {
  useDealStore().markAsTraded(event.listing);
};

const removeNegotiationFromStore = (event: DealCreated) => {
  useNegotiationStore().remove({
    negotiationId: event.negotiationId,
    listingId: event.listing.id,
  });
};

const removeActiveListingFromStore = (event: DealCreated) => {
  useListingStore().removeFromActiveHistory(event.listing);
};

export default [
  dealCreatedNotification,
  addCompanyDeal,
  addToTradedDeals,
  removeNegotiationFromStore,
  removeActiveListingFromStore,
];
