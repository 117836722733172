import { useApi } from '@/api';
import router from '@/router';
import { useNegotiationStore } from '@/stores';

export default <E>(event: E & { id: number }) => {
  const listingId = event.id;
  const route = router.currentRoute.value;
  const isListingPage = route.name === 'listings';
  const isSameListing = Number(route.params.id) === listingId;

  if (!isListingPage || !isSameListing) {
    return;
  }

  useApi()
    .negotiation.findAllByListing(listingId)
    .then((negotiations) => {
      useNegotiationStore().negotiations[listingId] = negotiations;
    });
};
