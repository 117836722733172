<!-- eslint-disable vue/v-on-function-call -->
<template>
  <div>
    <Component
      :is="searchable ? Combobox : Listbox"
      :model-value="
        Array.isArray(modelValue) ? modelValue.map(toRaw) : modelValue
      "
      :multiple="multiple || allowEmpty"
      :by="by"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <Float :offset="4" :placement="placement">
        <DropdownButton
          :as="searchable ? ComboboxButton : ListboxButton"
          v-bind="$attrs"
          :variant="size"
          class="disabled:cursor-not-allowed disabled:opacity-50"
          :has-values="
            Array.isArray(modelValue) ? modelValue.length > 0 : modelValue
          "
        >
          <Icon v-if="icon" :icon="icon" class="mr-2 text-vesper-blue/50" />
          <slot
            v-if="Array.isArray(modelValue) ? modelValue.length : modelValue"
            :item="modelValue"
            name="label"
          >
            <slot :item="modelValue">{{ modelValue }}</slot>
          </slot>
          <template v-else-if="valuePlaceholder">{{
            valuePlaceholder
          }}</template>
          <template v-else>&nbsp;</template>
        </DropdownButton>

        <Component
          :is="searchable ? ComboboxOptions : ListboxOptions"
          as="template"
        >
          <DropdownListBody
            :category-grouping-label="categoryGroupingLabel"
            :dropdown-test-handle="dropdownTestHandle"
            :items="items"
            :model-value="modelValue"
            :allow-empty="allowEmpty"
            :multiple="multiple"
            :search-input-placeholder="searchInputPlaceholder"
            :search-props="searchProps"
            :searchable="searchable"
            :variant="expandedVariant"
            @update:model-value="$emit('update:modelValue')"
          >
            <template v-if="$slots.default" #default="{ item }">
              <slot :item="item" />
            </template>
          </DropdownListBody>
        </Component>
      </Float>
    </Component>
    <span v-if="errorMessage" class="text-xs text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script setup lang="ts">
import DropdownButton from '@/components/DropdownButton.vue';
import DropdownListBody from '@/components/DropdownListBody.vue';
import Float from '@/components/Float.vue';
import Icon from '@/components/Icon.vue';
import type { Placement } from '@floating-ui/core';
import {
  Combobox,
  ComboboxButton,
  ComboboxOptions,
  Listbox,
  ListboxButton,
  ListboxOptions,
} from '@headlessui/vue';
import { useField } from 'vee-validate';
import { computed, PropType, toRaw, toRef } from 'vue';

type Display = 'button' | 'inline';
type Scheme = 'dark' | 'light';
type Variant = [Display, Scheme] | Display | Scheme;

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  categoryGroupingLabel: {
    type: [String, Array] as PropType<string | string[]>,
    default: 'common.products',
  },
  searchInputPlaceholder: {
    type: String,
    default: '',
  },
  modelValue: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: [Object, Array, String, Number] as PropType<any | any[]>,
    default: () => [],
  },
  items: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: Array as PropType<any[]>,
    default: () => [],
  },
  valuePlaceholder: {
    type: String,
    default: '',
  },
  dropdownTestHandle: {
    type: String,
    default: null,
  },
  allowEmpty: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  searchProps: {
    type: Array as PropType<string[]>,
    default: () => ['name', 'slug'],
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  offset: {
    type: Object,
    default: null,
  },
  placement: {
    type: String as PropType<Placement>,
    default: 'bottom-start',
  },
  variant: {
    type: [Array, String] as PropType<Variant>,
    default: null,
  },
  size: {
    type: String as PropType<'small' | 'big'>,
    default: 'small',
  },
  icon: {
    type: String,
    default: '',
  },
  by: {
    type: [String, Function] as PropType<
      string | ((left: unknown, right: unknown) => boolean)
    >,
    default: () => (left: unknown, right: unknown) => left === right,
  },
});

const emit = defineEmits(['update:modelValue']);

const expandedVariant = computed((): [Display, Scheme] => {
  if (Array.isArray(props.variant)) {
    return props.variant;
  }
  if (props.variant === 'button' || props.variant === 'inline') {
    return [props.variant, 'light'];
  }
  if (props.variant === 'light' || props.variant === 'dark') {
    return ['button', props.variant];
  }
  return ['button', 'light'];
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validate needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { errorMessage } = useField(name, undefined, {
  syncVModel: true,
});
</script>
