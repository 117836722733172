import { Api } from '@/api/types';
import { UserStore } from '@/stores';
import { AxiosInstance, AxiosRequestConfig, Method } from 'axios';

export default class Base implements Api {
  public constructor(
    protected readonly httpClient: AxiosInstance,
    protected readonly userStore: () => UserStore
  ) {}

  public async request<T>(
    method: Method,
    url: string,
    data: Record<string, unknown> = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const response = await this.httpClient.request<T>({
      url,
      method,
      data,
      ...config,
    });
    return response.data;
  }

  public authorizedRequest<T>(
    method: Method,
    url: string,
    data: Record<string, unknown> = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    config.headers = {
      Authorization: `Bearer ${this.userStore().token}`,
      ...(config.headers ?? {}),
    };
    config.params = {
      ...(config.params ?? {}),
    };
    return this.request(method, url, data, config);
  }

  public get<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    return this.authorizedRequest('get', url, {}, config);
  }

  public post<T>(
    url: string,
    data: Record<string, unknown> = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.authorizedRequest('post', url, data, config);
  }

  public patch<T>(
    url: string,
    data: Record<string, unknown> = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.authorizedRequest('patch', url, data, config);
  }

  public put<T>(
    url: string,
    data: Record<string, unknown> = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.authorizedRequest('put', url, data, config);
  }

  public delete<T>(
    url: string,
    data: Record<string, unknown> = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.authorizedRequest('delete', url, data, config);
  }
}
