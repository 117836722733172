import { RouteRecordRaw } from 'vue-router';

export const appRoutes: RouteRecordRaw[] = [
  {
    path: '',
    redirect: { name: 'overview' },
  },
  {
    path: '/overview/products/:productSlug?',
    name: 'overview',
    component: () => import('@/pages/Overview.vue'),
  },
  {
    path: '/overview/products/:productSlug/listings/:id',
    name: 'listings',
    component: () => import('@/pages/Overview.vue'),
  },
  {
    path: '/your-deals',
    name: 'your-deals',
    component: () => import('@/pages/YourDeals.vue'),
  },
  {
    path: '/your-account',
    name: 'your-account',
    component: () => import('@/pages/YourAccount.vue'),
  },
];
