<template>
  <div class="py-1 pl-3 pr-4 text-sm/6">
    <Icon
      icon="fa-regular fa-calendar-minus"
      fixed-width
      class="mr-3 text-slate-500"
    />
    <span class="mr-2 text-slate-500">{{
      $t('next-market-event.next-event')
    }}</span>
    <span v-if="marketStore.nextMarket">{{
      $format(
        new Date(marketStore.nextMarket.startAt),
        'datetime',
        formats.date.MONTH_DAY
      )
    }}</span>
    <span v-else>{{ $t('next-market-event.na') }}</span>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { useMarketStore } from '@/stores';
import { formats } from '@/translations';

const marketStore = useMarketStore();
</script>
