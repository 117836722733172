<template>
  <p class="mb-2 text-sm/6 text-slate-500">
    {{
      form.type === 'OFFER'
        ? $t('company-factory.approved-product-origin.banner-title', {
            count: form.factories.length,
            separator: ',',
          })
        : $t('company-factory.approved-product-origin.banner-title-approved', {
            count: form.factories.length,
            separator: ',',
          })
    }}
    {{
      $t(`audience.label`, {
        party: $t(`audience.${form.type}.${form.audience}`),
      })
    }}
  </p>
  <div
    v-for="(companies, locationId) in groupedFactories"
    :key="locationId"
    class="mb-3 text-sm/6"
  >
    <p class="mr-3 text-slate-500">
      {{ shipmentStore.getLocationById(locationId)?.name }}
    </p>
    <template v-for="(codes, companyName) in companies" :key="companyName">
      <div>
        <span class="text-gray-800">{{ `${companyName}: ` }}</span>
        <span class="text-slate-500">{{ codes.join(', ') }}</span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useGroupedFactories } from '@/composables';
import { useShipmentStore } from '@/stores';
import { ListingForm } from '@/types';
import { computed } from 'vue';

const shipmentStore = useShipmentStore();

const props = defineProps<{
  form: ListingForm;
}>();

const factoriesArray = computed(() =>
  props.form.factories.map((factory) => factory.value)
);
const groupedFactories = useGroupedFactories(factoriesArray);
</script>
