import { Incoterm, ListingType } from '@/types';

const bidIncoterms: Incoterm[] = [
  'EXW',
  'FOB',
  'CIP',
  'DPU',
  'DAP',
  'DDP',
] as const;

const offerIncoterms: Incoterm[] = ['EXW', 'FCA', 'DAP'] as const;

export function useIsAllowedIncoterm(
  type: ListingType,
  incoterm: Incoterm
): boolean {
  if (type === 'OFFER') {
    return offerIncoterms.includes(incoterm);
  }

  return bidIncoterms.includes(incoterm);
}
