<template>
  <input
    v-if="Array.isArray(modelValue) || modelValue instanceof Set"
    v-model="computedValue"
    v-bind="defaults"
    :value="value"
  />

  <input
    v-if="modelValue !== undefined"
    v-model="computedValue"
    v-bind="defaults"
    :true-value="trueValue"
    :false-value="falseValue"
  />

  <input
    v-else
    v-bind="defaults"
    :value="value"
    :checked="checked"
    @change="$emit('change', ($event.target as HTMLInputElement).checked)"
    @input="$emit('input', ($event.target as HTMLInputElement).checked)"
  />

  <Icon
    :icon="icon"
    :class="$attrs.class"
    class="group-hover:text-vesper-accent"
  />
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { computed, PropType } from 'vue';

const props = defineProps({
  variant: {
    type: String as PropType<'outline' | 'solid'>,
    default: 'outline',
  },
  modelValue: {
    type: null,
    default: undefined,
  },
  value: {
    type: null,
    default: undefined,
  },
  trueValue: {
    type: null,
    default: true,
  },
  falseValue: {
    type: null,
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  indeterminate: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'input', 'change']);

const computedValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const defaults = computed(() => ({
  id: props.id,
  name: props.name,
  disabled: props.disabled,
  type: 'checkbox',
  class: 'peer h-px w-px opacity-0',
  required: props.required,
}));

const isChecked = computed(() => {
  if (props.checked) {
    return true;
  }
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(props.value);
  }
  if (props.modelValue instanceof Set) {
    return props.modelValue.has(props.value);
  }
  return props.modelValue === props.trueValue;
});

const icon = computed(() => {
  let state = 'fa-square';

  if (props.indeterminate) {
    state = 'fa-square-minus';
  }
  if (isChecked.value) {
    state = 'fa-square-check';
  }
  const style =
    props.variant === 'solid' && isChecked.value ? 'fa-solid' : 'fa-regular';

  return `${style} ${state}`;
});
</script>
