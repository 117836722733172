<template>
  <Teleport to="body">
    <Modal
      v-if="listing && dealData"
      :is-open="isVisible"
      body-class="p-0 max-w-screen-md"
      footer-class="p-0"
      @update:is-open="dealModalStore.hideModal()"
    >
      <template #header>
        <h1 class="text-lg/none">
          {{ $t('modal-accept-negotiation.congratulations') }}
        </h1>
      </template>
      <template #body>
        <CongratulationsCard />
        <ListingInfoCard :listing="listing" />
        <DealContactInfo
          v-if="contactInfo"
          :title="$t('deals.counter-party')"
          :contact-info="contactInfo"
        />
        <TotalPrice :unit-price="dealData.price" :volume="dealData.volume" />
      </template>
      <template #footer>
        <div class="flex justify-end px-6 pb-6 pt-4 text-right">
          <Button variant="dark" @click="dealModalStore.hideModal()">
            <span>{{ $t('common.ok') }}</span>
          </Button>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue';
import CongratulationsCard from '@/components/CongratulationsCard.vue';
import DealContactInfo from '@/components/DealContactInfo.vue';
import ListingInfoCard from '@/components/ListingInfoCard.vue';
import Modal from '@/components/Modal.vue';
import TotalPrice from '@/components/TotalPrice.vue';
import { useDealModalStore, useListingStore, useUserStore } from '@/stores';
import { computed } from 'vue';

const dealModalStore = useDealModalStore();
const userStore = useUserStore();

const isVisible = computed(() => dealModalStore.isVisible);
const dealData = computed(() => dealModalStore.eventData);
const listing = computed(() => {
  if (!dealData.value) {
    return null;
  }

  return useListingStore().enriched(dealData.value?.listing);
});

const contactInfo = computed(() => {
  if (!dealData.value) {
    return null;
  }

  if (userStore.company?.email === dealData.value.companyContactInfo.email) {
    return dealData.value.counterPartyContactInfo;
  }

  return dealData.value.companyContactInfo;
});
</script>
