<template>
  <div class="mt-1 flex flex-col px-6">
    <div class="flex border-b pb-4 text-sm/4">
      <p class="font-medium">{{ $t('modal-accept-negotiation.volume') }}</p>
      <p class="ml-auto">{{ $format(volume, 'volume') }}</p>
    </div>
    <div class="flex border-b py-4 text-sm/4">
      <p class="font-medium">{{ $t('modal-accept-negotiation.price') }}</p>
      <p class="ml-auto">{{ $format(unitPrice, 'money') }}</p>
    </div>
    <div class="flex py-4 text-xl/6">
      <p class="font-medium">{{ $t('modal-accept-negotiation.total') }}</p>
      <p class="ml-auto">{{ $format(totalPrice, 'money') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UnitPrice, Volume } from '@/types/common';
import { computed } from 'vue';

const props = defineProps<{
  unitPrice: UnitPrice;
  volume: Volume;
}>();

const totalPrice = computed(() => {
  return Number(props.unitPrice.value) * Number(props.volume.amount);
});
</script>
