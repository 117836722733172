import { EnrichedListingPreview, NegotiationPreview } from '@/types';
import { computed } from 'vue';

export function useCounterParty(
  listing: EnrichedListingPreview,
  negotiation?: NegotiationPreview
) {
  return computed(() => {
    if (negotiation) {
      return listing.isYourListing ? negotiation.owner : listing.owner;
    }
    return listing.owner;
  });
}
