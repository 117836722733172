import isEqual from 'lodash/isEqual';
import { computed, ref } from 'vue';

type Props<T> = Readonly<{
  modelValue: T | T[] | null;
  items: T[];
  allowEmpty?: boolean;
  multiple?: boolean;
  max?: number;
}>;

type Emit<T> = (event: 'update:modelValue', value: T[]) => void;

export function useDropdown<T>(
  props: Props<T>,
  emit: Emit<T>,
  filter: (item: T, query: string) => boolean
) {
  const searchQuery = ref('');

  const queryMatches = (item: T, query: string): boolean => {
    if (query.length === 0) {
      return true;
    }

    return filter(item, query);
  };

  const filteredItems = computed(() => {
    const query = searchQuery.value.toLowerCase();

    return props.items.filter((item) => queryMatches(item, query));
  });

  const isChecked = (item: T): boolean => {
    if (Array.isArray(props.modelValue)) {
      return props.modelValue.some((checkedItem) => isEqual(checkedItem, item));
    }

    return props.modelValue === item;
  };

  const isDisabled = (item: T): boolean => {
    if (Array.isArray(props.modelValue)) {
      if (
        props.max &&
        props.modelValue.length === props.max &&
        !isChecked(item)
      ) {
        return true;
      }

      if (props.allowEmpty || !props.multiple) {
        return false;
      }

      return isChecked(item) && props.modelValue.length === 1;
    }

    return false;
  };

  return {
    searchQuery,
    filteredItems,
    isChecked,
    isDisabled,
  };
}
