import '@/styles/index.css';
import '@/extensions';
import App from '@/App.vue';
import { initialiseSentry } from '@/lib/sentry-initialiser';
import router from '@/router';
import i18n from '@/translations/plugin';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

console.log('Release', __RELEASE_VERSION__);

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(autoAnimatePlugin);

initialiseSentry(app, router);

app.mount('#app');
