import { Factory, GroupedFactoriesByCountryAndCompany } from '@/types';
import { computed, ComputedRef, Ref } from 'vue';

export function useGroupedFactories(
  factories: Ref<Factory[]>
): ComputedRef<GroupedFactoriesByCountryAndCompany> {
  return computed(() => {
    // Group factories by locationId, then by companyName
    return factories.value.reduce(
      (
        groupedFactories: GroupedFactoriesByCountryAndCompany,
        factory: Factory
      ) => {
        const { locationId, owner, code } = factory;

        if (!groupedFactories[locationId]) {
          groupedFactories[locationId] = {};
        }

        if (!groupedFactories[locationId][owner.name]) {
          groupedFactories[locationId][owner.name] = [];
        }

        groupedFactories[locationId][owner.name].push(code);

        return groupedFactories;
      },
      {}
    );
  });
}
