import { useFilterStore } from '@/stores';
import { ListingPreview } from '@/types';
import { MarketDealPreview } from '@/types/deal';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useDealStore = defineStore('deals', () => {
  const filterStore = useFilterStore();
  const allMarketDeals = ref<MarketDealPreview[]>([]);
  const allCompanyDeals = ref<MarketDealPreview[]>([]);
  const traded = ref<Record<number, number>>({});

  const marketDeals = computed(() => filterStore.apply(allMarketDeals.value));
  const companyDeals = computed(() => filterStore.apply(allCompanyDeals.value));

  const setMarketDeals = (value: MarketDealPreview[]) =>
    (allMarketDeals.value = value);
  const setCompanyDeals = (value: MarketDealPreview[]) =>
    (allCompanyDeals.value = value);

  const addMarketDeal = (deal: MarketDealPreview) => {
    const dealExists = allMarketDeals.value.some((item) => item.id === deal.id);

    if (dealExists) {
      // Since deals are static, we only push to the store if it's not already there.
      return;
    }

    allMarketDeals.value.unshift(deal);
  };

  const addCompanyDeal = (deal: MarketDealPreview) => {
    const dealExists = allCompanyDeals.value.some(
      (item) => item.id === deal.id
    );

    if (dealExists) {
      // Since deals are static, we only push to the store if it's not already there.
      return;
    }

    allCompanyDeals.value.unshift(deal);
  };

  const markAsTraded = (listing: ListingPreview) => {
    traded.value[listing.id] = listing.id;
  };

  const wasTraded = function (listing: ListingPreview) {
    return traded.value[listing.id] === listing.id;
  };

  function clear() {
    allMarketDeals.value = [];
    allCompanyDeals.value = [];
    traded.value = {};
  }

  return {
    addCompanyDeal,
    addMarketDeal,
    clear,
    companyDeals,
    marketDeals,
    setMarketDeals,
    setCompanyDeals,
    markAsTraded,
    wasTraded,
  };
});
